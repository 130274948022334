<template>
  <div>

    <infoHeader :content_name="'查看稿件内容'" />
    <el-descriptions title="问题信息" direction="vertical" v-if="data.question">
        <template slot="extra">
            <el-button type="primary" @click="toUpdatePage">更新或补充该稿件</el-button>
        </template>
        <el-descriptions-item label="问题名">{{data.question.abnormal_question}}</el-descriptions-item>
        <el-descriptions-item label="问题创建者">{{data.question.author}}</el-descriptions-item>
        <el-descriptions-item label="问题类目" v-if="data.question.question_type">{{data.question.question_type.type_name}}</el-descriptions-item>
        <el-descriptions-item label="软硬类型">{{data.question.types}}</el-descriptions-item>
        <el-descriptions-item label="相关搜索关键词">{{data.question.question_suit}}</el-descriptions-item>
        <el-descriptions-item label="更新或补充次数">{{data.question.update_count}}</el-descriptions-item>
    </el-descriptions>

    <el-divider content-position="left">解决方法</el-divider>

    <el-row :gutter="20" v-if="data.question">
    <el-col :span="18">
            <el-descriptions title="稿件内容">
        </el-descriptions>
        <pre v-html="data.question.resolvent"></pre>
    </el-col>
    <el-col :span="6">
        <el-descriptions title="更新记录"></el-descriptions>
        <el-col :span="24" v-for="(i, index) in data.update" :key="i.create_time + index" style="margin-bottom: 30px;">
            <el-card shadow="hover">

            <el-form ref="form" label-position="left">

                <el-form-item label="更新类型:">
                    <span>{{ i.update_illustrate }}</span>
                </el-form-item>

                <el-form-item label="更新者:">
                    <span>{{ i.update_account }}</span>
                </el-form-item>

                <el-form-item label="更新类型:">
                    <span>{{ i.update_type }}</span>
                </el-form-item>

                <el-form-item label="提交时间:">
                    <span>{{ i.create_time }}</span>
                </el-form-item>

            </el-form>

            </el-card>
        </el-col>

        
    </el-col>
    </el-row>

    

  </div>
</template>

<script>
import { equipmentFaultManageGetinfo, equipmentFaultManageQuestionTypeQuery } from "@/api/manage/operation/equipment_fault_manage.js";
// @ is an alias to /src
export default {
    name: '',
    data() {
        return {
            question_id: this.$route.query.id,
            option: {
              question_type: []
            },
            data:{

            }
        };
    },
    components: {
    },
    created(){
      this.getInfo()
      this.getOption()
    },
    methods:{
      getOption(){
        equipmentFaultManageQuestionTypeQuery({}).then((res) => {
            this.option.question_type = res.data;
        });
      },
      getInfo(){
        equipmentFaultManageGetinfo({
            id: this.question_id
        }).then((res) => {
            this.data = res.data;
        });
      },
      toUpdatePage(){
        this.$router.push({name:'equipmentFaultManage_update', query:{
            id: this.question_id
        }});
      }
    }
}
</script>
<style lang="scss" scoped>
.el-form-item{margin-bottom: 0;}
</style>